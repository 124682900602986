@import './../../../styles/sass-resources/sass-resources';

.navbar {
  position: sticky;
  top: 0;
  width: 100%;
  z-index: var(--z-index__global-header);
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid var(--colors__navy);
  pointer-events: auto;

  @include transition(background-color);

  background: var(--colors__light-silver);

  .menu-open & {
    background: var(--colors__royal);
    color: var(--colors__white);

    --logo__color: var(--colors__white);
  }
}

.logo {
  @include background-animation;

  --colors__background: var(--colors__red);

  .menu-closed & {
    --logo__color: var(--colors__red);
  }

  display: flex;
  align-items: center;
  padding: var(--spacing-compact) var(--responsive-spacing);
  border-right: 1px solid var(--colors__navy);
  color: var(--logo__color);

  &:hover {
    --logo__color: var(--colors__white);
  }

  @include media('>=tablet-landscape') {
    padding: var(--spacing) var(--responsive-spacing);
  }
}

.logo-icon {
  width: 73.674px;
  height: 24px;

  @include media('>=tablet-landscape') {
    width: 132px;
    height: 43px;
  }
}

.mega-menu-links {
  padding: var(--spacing-tight);
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;

  @include media('>=phone-small') {
    padding: 0 var(--responsive-spacing) 0 var(--spacing-compact);
    gap: var(--spacing);
  }

  @include media('>=tablet-landscape') {
    padding: 0 var(--responsive-spacing) 0 var(--spacing);
  }
}

.icons {
  display: flex;
  flex-direction: row;
  gap: var(--spacing-tight);
  align-items: center;

  @include media('>=phone-small') {
    gap: var(--spacing);
  }
}

.course {
  @include fonts__h6;

  @include media('>=tablet-landscape') {
    @include fonts__h4;
  }
}

.search {
  width: 1rem;
  height: 1rem;
}

.primary-links {
  display: none;

  @include media('>=desktop') {
    display: flex;
    gap: var(--spacing);
  }
}

.caret {
  width: 16px;

  @include transition(transform);
}

.primary-link {
  font-weight: 700;
  display: flex;
  gap: var(--spacing-tight);
  align-items: center;

  &[data-state='open'] {
    @include background-animation--small(true);

    .caret {
      transform: rotateX(180deg);
    }
  }

  &:not([data-state='open']) {
    @include background-animation--small(false);
  }
}

.mobile-menu-toggle {
  @include media('>=desktop') {
    display: none;
  }
}

.tabs-root,
.primary-links,
.primary-link {
  height: 100%;
}

.tabs-root {
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
}

.skip-link {
  position: absolute;
  top: 0.5rem;
  left: 1.5rem;
  z-index: var(--z-index__modal);
  line-height: 1;
  opacity: 0;

  @include transition(opacity);

  &:focus-visible {
    opacity: 1;
  }
}
