@mixin background-animation($on-hover: true, $on-active: true) {
  --colors__background: var(--colors__navy);

  background-repeat: no-repeat;
  background-position: var(--background-position, right 0);
  background-size: var(--background-size, 0% 100%);
  background-image: linear-gradient(
    to right,
    var(--colors__background) 0%,
    var(--colors__background) 100%
  );

  @include transition(
    color 500ms ease-in-out,
    background-size 500ms ease-in-out,
    background-position 1ms ease-in-out,
    border 500ms ease-in-out,
    opacity 500ms ease-in-out
  );

  @if $on-hover {
    &:hover {
      --background-size: 100% 100%;
      --background-position: left 0px;
      --transform-icon: 5px;
    }
  } @else {
    &:hover {
      opacity: 0.7;
    }
  }

  @if $on-active {
    &[data-state='open'],
    &[data-selected] {
      --background-size: 100% 100%;
      --background-position: left 0px;
      --transform-icon: 5px;
    }
  }
}

@mixin background-animation--small($on-hover: true, $on-active: true) {
  @include background-animation($on-hover, $on-active);

  background-position: var(--background-position, right 100%);
  background-size: var(--background-size, 0% 3px);

  @if $on-hover {
    &:hover {
      --background-size: 100% 3px;
      --background-position: left 100%;
    }
  }

  @if $on-active {
    &[data-state='open'],
    &[data-selected] {
      --background-size: 100% 3px;
      --background-position: left 100%;
    }
  }
}
