@import './../../../../../styles/sass-resources/sass-resources';

.top-row {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  align-items: center;
  margin-top: var(--spacing);
}

.results-count {
  @include fonts__eyebrow;
}

.clear-button {
  color: var(--colors__navy);

  @include fonts__primary-cta;

  display: flex;
  gap: var(--spacing-compact);
  align-items: center;
  white-space: nowrap;

  @include background-animation--small(false);
}

.clear-icon {
  width: 1rem;
  height: 1rem;
}

.items {
  margin-top: var(--responsive-spacing);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-compact);

  &--grid {
    @include media('>=tablet-portrait') {
      flex-flow: row wrap;
    }
  }

  @include media('>=tablet-portrait') {
    &--list {
      gap: 0;
    }
  }
}

.item {
  &:empty {
    display: none;
  }

  .items--grid & {
    @include media('>=tablet-portrait') {
      width: calc(50% - var(--spacing-compact) / 2);
    }
  }

  @include media('>=tablet-portrait') {
    .items--list & {
      &:not(:last-child) {
        --CoreMaterialCard__border-bottom: none;
        --CoreVideoCard__border-bottom: none;
      }
    }
  }
}

.no-results-message {
  margin-top: var(--responsive-spacing);

  @include fonts__h5;
}

.pagination {
  margin-top: var(--responsive-spacing);
}
