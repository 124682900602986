@import './../../../styles/sass-resources/sass-resources';

$max-copy-width: 880px;

.main {
  padding: var(--responsive-spacing);
}

.header {
  @include fonts__h3;

  margin-bottom: var(--spacing-spacious);
}

.description {
  margin-bottom: var(--spacing-cavernous);
}

.header,
.description {
  max-width: $max-copy-width;
}

.card-list {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: var(--spacing);

  .card-list-item {
    height: 100%;
    width: 100%;
    max-width: 100%;
  }

  &.faculty {
    .card-list-item {
      aspect-ratio: 0.9;
    }

    @include media('>=tablet-portrait') {
      grid-template-columns: repeat(2, 1fr);
    }

    @include media('>=desktop') {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  &.participant {
    .card-list-item {
      aspect-ratio: 0.82;
    }

    @include media('>=tablet-portrait') {
      grid-template-columns: repeat(2, 1fr);
    }

    @include media('>=tablet-landscape') {
      grid-template-columns: repeat(3, 1fr);
    }

    @include media('>=desktop') {
      grid-template-columns: repeat(4, 1fr);
    }
  }
}
