@import './../../../styles/sass-resources/sass-resources';

.main {
  background: var(--colors__navy);
  color: var(--colors__white);
  display: flex;
  padding: 0;
  flex-direction: column;
  overflow: hidden;
  position: relative;

  @include media('>=tablet-landscape') {
    flex-direction: row;
  }
}

.intro {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-spacious);
  padding: var(--responsive-spacing);
  border-bottom: 1px dotted var(--colors__white);

  @include media('>=tablet-landscape') {
    width: 40%;
    border-bottom: none;
    border-right: 1px dotted var(--colors__white);
  }
}

.header {
  @include fonts__h3;
}

.form {
  padding: var(--responsive-spacing);
  display: flex;
  flex-direction: column;
  gap: var(--spacing);

  @include media('>=tablet-landscape') {
    width: 60%;
    gap: var(--spacing-spacious);
  }
}

.text-input {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-tight);
}

.text-area {
  background: var(--colors__navy);
  border: 1px solid var(--colors__white);
  padding: var(--spacing-compact);
  color: var(--colors__white);
  resize: none;

  &::placeholder {
    opacity: 0.7;
    color: var(--colors__white);
  }

  @include focus;
}

.character {
  align-self: flex-end;

  @include fonts__paragraph--small;
}

.character-error {
  font-weight: 700;
}

.filters {
  display: flex;
  flex-direction: column;
  gap: var(--spacing);

  @include media('>=tablet-landscape') {
    flex-direction: row;
  }
}

.dropdown {
  flex: 1;
}

.submit {
  display: flex;
  flex-direction: column;
  gap: var(--spacing);
  justify-content: flex-end;

  @include media('>=tablet-landscape') {
    flex-direction: row;
  }
}

.optional-fields {
  display: flex;
  flex-direction: column;
  gap: var(--spacing);

  @include media('>=tablet-landscape') {
    gap: var(--spacing-spacious);
  }
}

.optional-fields-label {
  @include fonts__h4;
}

.optional-fields-description {
  margin-top: var(--spacing-compact);
}

.help-text {
  text-align: right;
  margin-bottom: var(--spacing);
}

.asterik {
  color: var(--colors__white);
}

.hidden {
  display: none;

  @include media('>=tablet-landscape') {
    display: block;
    visibility: hidden;
    width: 0;
    padding: 0;
  }
}

.submit-message {
  position: absolute;
  right: 0;
  background: var(--colors__navy);
  height: 100%;
  top: 0;
}
