@import './../../../styles/sass-resources/sass-resources';

.main {
  @include fonts__primary-cta;
  @include background-animation;
  @include focus-inset;

  --colors__background: var(--colors__yellow);
  --BoxCta__border-style: 1px solid var(--colors__navy);

  display: inline-flex;
  gap: var(--spacing-compact);
  align-items: center;
  border: var(--BoxCta__border-style);
  border-bottom: var(--BoxCta__border-bottom, var(--BoxCta__border-style));
  border-right: var(--BoxCta__border-right, var(--BoxCta__border-style));
  padding: var(--spacing-compact);

  :global(.dark) & {
    border: 1px solid var(--colors__white);

    &:hover {
      border-color: var(--colors__background);
    }
  }

  &.secondary {
    --colors__background: var(--colors__royal);
  }

  &[disabled] {
    opacity: 0.25;
    pointer-events: none;
  }

  &:hover:not([disabled]) {
    color: var(--colors__navy);

    --transform-icon: 5px;

    &.secondary {
      color: var(--colors__white);
    }
  }
}

.icon {
  @include transition(transform);

  transform: translateX(var(--transform-icon));
  height: 1rem;
  width: 1rem;
}
