/*
 *  Variables
 */

$fonts__font-family-mark-ot: 'Mark OT';
$fonts__font-family-copernicus: 'Copernicus';
$fonts__font-family__sans-serif: $fonts__font-family-mark-ot, arial, sans-serif;
$fonts__font-family__serif: $fonts__font-family-copernicus, georgia, serif;
$fonts__tablet-breakpoint: '>=tablet-landscape';
$fonts__desktop-breakpoint: '>=desktop';
$fonts__font-weight--light: 300;
$fonts__font-weight--regular: 400;
$fonts__font-weight--medium: 500;
$fonts__font-weight--semi-bold: 600;
$fonts__font-weight--bold: 700;
$fonts__font-weight--heavy: 800;

/*
 *  Mixins
 */

@mixin font-face($font-name, $file-path-location, $font-weight, $font-style) {
  @font-face {
    font-family: $font-name;
    src: url(#{$file-path-location});
    font-weight: $font-weight;
    font-style: $font-style;
    font-display: swap;
  }
}

/*
 *  Base font mixins
 */

@mixin fonts__sans-serif-medium() {
  font-family: $fonts__font-family__sans-serif;
  font-weight: $fonts__font-weight--medium;
}

@mixin fonts__sans-serif-medium-italic() {
  font-family: $fonts__font-family__sans-serif;
  font-weight: $fonts__font-weight--medium;
  font-style: italic;
}

@mixin fonts__sans-serif-regular() {
  font-family: $fonts__font-family__sans-serif;
  font-weight: $fonts__font-weight--regular;
}

@mixin fonts__serif-semi-bold() {
  font-family: $fonts__font-family__serif;
  font-weight: $fonts__font-weight--semi-bold;
}

@mixin fonts__serif-heavy() {
  font-family: $fonts__font-family__serif;
  font-weight: $fonts__font-weight--heavy;
}

@mixin fonts__serif-heavy-italic() {
  font-family: $fonts__font-family__serif;
  font-weight: $fonts__font-weight--heavy;
  font-style: italic;
}

// Mixins
@mixin fonts__h1() {
  @include fonts__sans-serif-medium;

  font-size: 32px;
  line-height: 1;

  @include media($fonts__tablet-breakpoint) {
    font-size: 64px;
  }

  @include media($fonts__desktop-breakpoint) {
    font-size: 90px;
  }
}

@mixin fonts__h2() {
  @include fonts__sans-serif-medium;

  font-size: 24px;
  line-height: 1;

  @include media($fonts__tablet-breakpoint) {
    font-size: 48px;
  }

  @include media($fonts__desktop-breakpoint) {
    font-size: 64px;
  }
}

@mixin fonts__h3() {
  @include fonts__sans-serif-medium;

  font-size: 24px;
  line-height: 1.3;

  @include media($fonts__tablet-breakpoint) {
    font-size: 32px;
  }

  @include media($fonts__desktop-breakpoint) {
    font-size: 48px;
  }
}

@mixin fonts__h4() {
  @include fonts__sans-serif-medium;

  font-size: 24px;
  line-height: 1;

  @include media($fonts__tablet-breakpoint) {
    font-size: 24px;
  }

  @include media($fonts__desktop-breakpoint) {
    font-size: 32px;
  }
}

@mixin fonts__h5() {
  @include fonts__sans-serif-medium;

  font-size: 24px;
  line-height: 1;
}

@mixin fonts__h6() {
  @include fonts__sans-serif-medium;

  font-size: 16px;
  line-height: 1;
}

@mixin fonts__paragraph() {
  @include fonts__sans-serif-regular;

  font-size: 14px;
  line-height: 1.5;

  @include media($fonts__tablet-breakpoint) {
    font-size: 16px;
  }
}

@mixin fonts__paragraph--small() {
  @include fonts__sans-serif-regular;

  line-height: 1.5;
  font-size: 12px;
}

@mixin fonts__paragraph--tiny() {
  @include fonts__sans-serif-medium;

  line-height: 1.5;
  font-size: 8px;
}

@mixin fonts__eyebrow() {
  @include fonts__serif-semi-bold;

  line-height: 1.2;
  font-size: 14px;

  @include media($fonts__tablet-breakpoint) {
    font-size: 16px;
  }
}

@mixin fonts__primary-cta {
  @include fonts__sans-serif-medium;

  font-weight: 700;
  font-size: 14px;
  line-height: 1.2;
}

@mixin fonts__track-tag {
  @include fonts__eyebrow;

  line-height: 1;
  border: 1px solid var(--colors__navy);
  padding: var(--spacing-tight) var(--spacing-compact);
  border-radius: 16px;
}

@mixin fonts__form-label {
  @include fonts__serif-semi-bold;

  font-size: 12px;
  line-height: 1.5;
}
