@import './../../../../../styles/sass-resources/sass-resources';

.main {
  --FiltersPanel__padding-top: 0rem;

  background-color: var(--colors__navy);
  color: var(--colors__light-silver);
  position: relative;
  padding-top: var(--FiltersPanel__padding-top);
  border-right: 1px solid var(--colors__navy);

  [data-scope='dialog'] & {
    --FiltersPanel__padding-top: var(--spacing);
  }
}

.container {
  overflow: auto;
  padding-bottom: var(--spacing);

  // This accounts for the height of the sticky cta button and the top padding of the container so the container doesn't overflow either of them
  position: sticky;
  top: var(--FiltersPanel__padding-top);
  height: calc(100dvh - var(--FiltersPanel__padding-top) - var(--CtaContainerRef__height));

  @include media('>=CoreAllCourseMaterialSearch__desktop') {
    top: calc(var(--NavigationHeader__height) + var(--FiltersPanel__padding-top));
    height: calc(100dvh - var(--CtaContainerRef__height) - var(--NavigationHeader__height));
  }
}

.wrapper {
  padding: var(--spacing);
}

.line {
  border-top: 1px dotted currentcolor;
}

.sticky-track {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  display: flex;
  align-items: flex-end;
  width: 100%;
  transform: translateX(-100%);
}

.cta-container {
  position: sticky;
  bottom: 0;
  transform: translateX(100%);
  width: 100%;
  background-color: var(--colors__navy);
}

.error-message {
  background-color: var(--colors__yellow);
  padding: var(--spacing-compact);
  margin: var(--spacing-tight);
  display: flex;
  align-items: center;
  gap: var(--spacing-compact);
}

.alert-icon {
  width: 1rem;
  height: 1rem;
  color: var(--colors__navy);
}

.error-message-label {
  color: var(--colors__navy);

  @include fonts__paragraph;
}

.cta {
  --BoxCta__border-bottom: none;
  --BoxCta__border-right: none;

  padding: var(--spacing);
  justify-content: center;
  background-color: var(--colors__royal);
  width: 100%;
}
