@mixin focus() {
  outline-offset: var(--focus-offset, 2px);

  &:focus-visible {
    outline: 2px dotted currentcolor;
  }
}

@mixin focus-inset() {
  @include focus;

  --focus-offset: -0.25rem;
}

@mixin focus-within {
  &:has(:focus-visible) {
    outline-offset: 0.25rem;
    outline: 2px dotted currentcolor;
  }
}

@mixin ark-focus {
  &[data-focus] {
    outline: 2px dotted currentcolor;
  }
}
