@import './../../../styles/sass-resources/sass-resources';

.root {
  display: flex;
  position: relative;
  align-items: center;
  gap: var(--spacing-tight);
  cursor: pointer;

  @include ark-focus;
}

.label {
  @include fonts__primary-cta;
}

.control {
  border: 2px solid var(--Switch__control-border-color, var(--colors__navy));
  width: 2rem;
  height: 1rem;
  background: transparent;
  border-radius: var(--border-radius);
  display: inline-flex;
  align-items: center;
  flex-shrink: 0;

  @include transition(background);

  &:is(:checked, [data-checked], [aria-checked='true'], [data-state='checked']) {
    background: var(--Switch__control-background-color-checked, var(--colors__navy));
  }
}

.thumb {
  background: var(--Switch__background-color, var(--colors__white));
  border: 2px solid var(--Switch__thumb-border-color, var(--colors__navy));
  border-radius: var(--border-radius);

  @include transition(background, transform);

  width: 1rem;
  height: 1rem;
  transform: translateX(-2px);

  &:is(:checked, [data-checked], [aria-checked='true'], [data-state='checked']) {
    transform: translateX(100%);
  }
}
