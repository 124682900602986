@import './../../../styles/sass-resources/sass-resources';

.main {
  --CoreAllCourseMaterialSearch__form-width: 28%;

  display: flex;
  background-color: var(--colors__light-silver);
  position: relative;
  min-height: 100vh;
}

.form {
  @include media('<CoreAllCourseMaterialSearch__desktop') {
    // Styles for the sticky filters modal toggle button
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: translateX(-100%);
    display: flex;
    align-items: flex-end;
    z-index: var(--z-index__search-filters-modal);
  }

  @include media('>=CoreAllCourseMaterialSearch__desktop') {
    width: var(--CoreAllCourseMaterialSearch__form-width);
    flex-shrink: 0;
  }
}

.filters {
  width: 100%;
  height: 100%;
}

.content {
  padding: var(--responsive-spacing);
  width: 100%;

  @include media('>=CoreAllCourseMaterialSearch__desktop') {
    width: calc(100% - var(--CoreAllCourseMaterialSearch__form-width));
  }
}

.eyebrow {
  @include fonts__eyebrow;

  margin-bottom: var(--spacing);

  @include media('>=tablet-landscape') {
    margin-bottom: var(--spacing-spacious);
  }
}

.header {
  @include fonts__h2;
}
