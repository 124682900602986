@import './../../../styles/sass-resources/sass-resources';

.main {
  display: flex;
  flex-direction: column;
  gap: var(--responsive-spacing);
}

.text {
  display: flex;
  flex-direction: column;
  gap: var(--spacing);
  max-width: 55rem;
}

.header {
  @include fonts__h3;
}

.materials {
  display: flex;
  flex-direction: column;
  gap: var(--spacing);
}

.grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: var(--spacing);

  @include media('>=tablet-landscape') {
    grid-template-columns: 1fr 1fr;
  }

  @include media('>=desktop') {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.label {
  @include fonts__h5;
}
