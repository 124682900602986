@import './../../../styles/sass-resources/sass-resources';

.main {
  background-color: var(--colors__white);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container {
  width: 100%;
  max-width: 200px;
  position: relative;
  aspect-ratio: 1;
}

.gif {
  --Image__object-fit: contain;
}
