@import './../../../styles/sass-resources/sass-resources';

.main {
  display: flex;
  gap: var(--spacing-spacious);
  flex-direction: column;
}

.header {
  @include fonts__h1;
}

.rich-text {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    &:not(:first-child) {
      margin-top: var(--spacing-spacious);
    }

    &:not(:last-child) {
      margin-bottom: var(--spacing-spacious);
    }
  }
}
