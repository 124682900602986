:root {
  --colors__red: #e81e34;
  --colors__royal: #5046ff;
  --colors__navy: #002269;
  --colors__yellow: #fafa69;
  --colors__silver: #dcdcdc;
  --colors__light-silver: #f5f5f5;
  --colors__white: #fff;
  --colors__charcoal: #54565b;
  --colors__black: #000;
  --color__body-copy-text: var(--colors__navy);
}
