@import './../../../../../styles/sass-resources/sass-resources';

.trigger {
  height: 4rem;
  width: 4rem;
  border-radius: 50%;
  background-color: var(--colors__royal);
  padding: var(--spacing-compact);
  border: 1px solid var(--colors__navy);
  position: sticky;
  bottom: var(--responsive-spacing);
  left: var(--responsive-spacing);
  z-index: var(--z-index__search-filters-trigger);

  @include media('height<phone-small') {
    height: 0.75rem;
    width: 0.75rem;
    padding: 0.75rem;
    left: 2px;
    bottom: 2px;
  }
}

.icon {
  width: 1rem;
  height: 1rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.backdrop {
  @include modal-backdrop;
}

.positioner {
  position: fixed;
  left: 0;
  top: 0;
  max-width: 448px;
  width: 100%;
  height: 100%;
  z-index: var(--z-index__search-filters-modal);
}

.content {
  height: 100%;

  &[data-state='open'] {
    @include slideRightIn;
  }

  &[data-state='closed'] {
    @include slideRightOut;
  }
}

.close-trigger {
  position: absolute;
  top: var(--spacing-compact);
  right: var(--spacing-compact);
  color: var(--colors__white);
  z-index: 1;

  @include focus;
}

.close-icon {
  width: 1rem;
  height: 1rem;
}
