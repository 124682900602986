@import './../../../../../styles/sass-resources/sass-resources';

.controls {
  display: flex;
  align-items: center;
  justify-content: center;
}

.item {
  display: contents;
}

.arrow {
  padding: var(--spacing-compact);

  &[disabled] {
    opacity: 0.25;
    pointer-events: none;
  }
}

.icon {
  width: 1rem;
  height: 1rem;
}

.number {
  padding: var(--spacing-compact);

  @include fonts__primary-cta;

  border-bottom: 3px solid transparent;

  &--active {
    border-color: var(--colors__navy);
    pointer-events: none;
  }

  &[aria-current='false'] {
    @include background-animation--small(false);
  }
}
