@import './../../../styles/sass-resources/sass-resources';

.main {
  display: flex;
  flex-direction: column;
  gap: var(--responsive-spacing);
}

.eyebrow {
  @include fonts__eyebrow;
}

.header {
  @include fonts__h3;
}

.text {
  display: flex;
  flex-direction: column;
  gap: var(--spacing);
}
