@import './../../../styles/sass-resources/sass-resources';

.main {
  background: var(--colors__royal);
  padding-top: var(--spacing);
  padding-bottom: var(--spacing);
  color: var(--colors__white);
  display: flex;
  justify-content: space-between;
  gap: var(--spacing);
  align-items: flex-start;
  flex-direction: column;
  position: relative;

  @include media('>=desktop') {
    align-items: center;
    flex-direction: row;
    gap: var(--spacing-spacious);
  }
}

.text,
.buttons {
  display: flex;
  gap: var(--spacing);

  @include media('>=desktop') {
    align-items: center;
  }
}

.text {
  flex-direction: column;

  @include media('>=desktop') {
    flex-direction: row;
    width: 70%;
  }
}

.label {
  @include fonts__h6;

  white-space: nowrap;
}

.close {
  width: 16px;

  @include media('<=desktop') {
    position: absolute;
    top: var(--spacing);
    right: var(--spacing);
  }
}
