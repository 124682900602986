@import './../../../../../styles/sass-resources/sass-resources';

.main {
  display: flex;
  flex-direction: column;
  gap: var(--spacing);
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.filter-label {
  @include fonts__h6;
}

.toggle {
  --Switch__background-color: var(--colors__navy);
  --Switch__thumb-border-color: var(--colors__light-silver);
  --Switch__control-border-color: var(--colors__light-silver);
  --Switch__control-background-color-checked: var(--colors__light-silver);
  --border-radius: 1rem;

  @include focus-within;
}

.group {
  display: contents;
}

.label {
  display: flex;
  align-items: flex-start;
  gap: var(--spacing-compact);
}

.checkbox {
  cursor: pointer;

  @include focus-within;
}

.box {
  width: 1.5rem;
  aspect-ratio: 1;
  border: 1px solid currentcolor;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.check {
  display: none;
  width: 16px;

  [data-state='checked'] & {
    display: block;
  }
}

.hidden-checkbox {
  display: none;
}
