:root {
  @include media('>=desktop') {
    font-size: 1vw;
  }
}

@include font-face(
  $fonts__font-family-mark-ot,
  './../../assets/fonts/MarkOT.otf',
  $fonts__font-weight--regular,
  normal
);
@include font-face(
  $fonts__font-family-mark-ot,
  './../../assets/fonts/MarkOT-Italic.otf',
  $fonts__font-weight--regular,
  italic
);
@include font-face(
  $fonts__font-family-mark-ot,
  './../../assets/fonts/MarkOT-Medium.otf',
  $fonts__font-weight--medium,
  normal
);
@include font-face(
  $fonts__font-family-copernicus,
  './../../assets/fonts/Copernicus-Semibold.otf',
  $fonts__font-weight--semi-bold,
  normal
);
@include font-face(
  $fonts__font-family-copernicus,
  './../../assets/fonts/Copernicus-SemiboldItalic.otf',
  $fonts__font-weight--semi-bold,
  italic
);
