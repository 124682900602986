@import './../../../styles/sass-resources/sass-resources';

// All commented out code will want to be filled out once font mixins have been created.

.main {
  --RichText__spacing-y: var(--spacing-compact);

  @include media('>=tablet-landscape') {
    --RichText__spacing-y: var(--spacing);
  }

  h1,
  :global(.h1-style) {
    @include fonts__h1;
  }

  h2,
  :global(.h2-style) {
    @include fonts__h2;
  }

  h3,
  :global(.h3-style) {
    @include fonts__h3;
  }

  h4,
  :global(.h4-style) {
    @include fonts__h4;
  }

  h5,
  :global(.h5-style) {
    @include fonts__h5;
  }

  h6,
  :global(.h6-style) {
    @include fonts__h6;
  }

  p,
  a,
  button,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: var(--RichText__spacing-y);
  }

  ul,
  ol {
    margin-left: var(--RichText__list-margin-left, 2rem);
    margin-top: var(--RichText__spacing-y);
    padding: 0;
  }

  ul li {
    list-style-type: var(--RichText__list-style-type, disc);
  }

  ol li {
    list-style-type: var(--RichText__list-style-type, decimal);
  }

  li:not(:first-child) {
    margin-top: var(--spacing-tight);
  }

  img,
  iframe,
  table,
  figure {
    max-width: 100%;
    margin-top: var(--spacing-spacious);
  }

  blockquote {
    @include fonts__h5;

    text-align: center;
    margin: var(--spacing-spacious) 0;
    padding: var(--spacing-spacious);
    position: relative;

    &::before,
    &::after {
      display: block;
      content: ' ';
      height: 1px;
      background: var(--colors__navy);
      width: 100%;
      left: 0;
      position: absolute;
    }

    &::before {
      margin-bottom: var(--spacing-spacious);
      top: 0;
    }

    &::after {
      margin-top: var(--spacing-spacious);
      bottom: 0;
    }
  }

  cite {
    display: block;
    margin-top: var(--RichText__spacing-y);
    padding-top: 0.5rem;
    text-align: center;
  }

  a {
    text-decoration: underline;
  }

  :global(.caption) {
    margin-bottom: var(--spacing-spacious);

    @include fonts__paragraph--small;
  }

  img + :global(.caption) {
    margin-top: 0;
  }

  :global(.primary-cta) {
    @include primary-cta;
  }

  :global(.date) {
    @include fonts__eyebrow;
  }

  > * {
    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}
