@import './../../../../../styles/sass-resources/sass-resources';

.main {
  @include media('>=desktop') {
    display: none;
  }
}

.content {
  &[data-state='closed'] {
    @include doNothing;
  }
}

.wrapper {
  background: var(--colors__royal);
  position: fixed;
  width: 100vw;
  top: calc(var(--NavigationHeader__height) - 0.0625rem);
  height: calc(100dvh - var(--NavigationHeader__height));
  z-index: var(--z-index__global-menu);
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  @media (max-height: $breakpoints__vertical-small) {
    overflow-y: auto;
  }

  [data-state='open'] & {
    @include slideInDown;
  }

  [data-state='closed'] & {
    @include slideOutDown;
  }
}

.caret {
  width: 16px;
  flex-shrink: 0;
}

.trigger {
  @include focus-inset;

  text-align: left;
  padding: var(--spacing-compact) var(--responsive-spacing);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  color: var(--colors__white);

  @include transition(color, background-color);

  &[data-state='open'] {
    background: var(--colors__navy);
    border-bottom: 1px dashed var(--colors__white);

    .caret {
      transform: rotateX(180deg);
    }
  }

  @include media('>=tablet-landscape') {
    padding: var(--spacing) var(--responsive-spacing);
  }
}

.trigger-label {
  @include fonts__h3;

  color: var(--colors__white);
}

.accordion-content {
  @include accordion-content;

  background: var(--colors__navy);
  color: var(--colors__white);
}

.links-label {
  display: block;

  @include fonts__h4;

  padding: var(--spacing-compact) var(--responsive-spacing);
}

.column {
  display: flex;
  flex-direction: column;
  padding: var(--spacing) 0;
}

.accordions {
  @media (min-height: $breakpoints__vertical-small) {
    overflow-y: auto;
    height: calc(100vh - var(--UserAccount__height));
  }
}

.mobile-menu-toggle {
  display: flex;
  align-items: center;
}

.lottie {
  display: flex;
  width: 16px;
}

.hidden-close {
  position: absolute;
  top: 0;
  right: var(--responsive-spacing);
  width: 16px;
  height: var(--NavigationHeader__height);
  display: flex;
  align-items: center;
  z-index: 10;
}

.hidden-close-trigger {
  width: 16px;
  height: 16px;
}
