@import './../../../styles/sass-resources/sass-resources';

.main {
  display: flex;
  flex-direction: column;
  gap: var(--responsive-spacing);
  text-align: center;
}

.text {
  display: flex;
  flex-direction: column;
  gap: var(--spacing);

  @include media('>=desktop') {
    gap: var(--spacing-spacious);
  }
}

.greeting {
  @include fonts__eyebrow;
}

.header {
  @include fonts__h1;

  max-width: 900px;
  margin: 0 auto;
}
