@mixin primary-cta {
  @include background-animation--small;
  @include fonts__primary-cta;

  --colors__component: var(--colors__navy);

  padding-bottom: var(--spacing-tight);
  justify-content: flex-start;
  gap: var(--spacing-compact);
  text-decoration: none;
  position: relative;
  width: fit-content;
  display: flex;
  color: var(--colors__component);
  align-items: center;
  padding-right: 5px;

  // We do all of the icons in css instead of JS so that they can be used in the Rich Text component without adding extra elements
  --icon: url('/icons/arrow-right-navy.svg');

  &::after {
    content: '';
    width: 16px;
    height: 16px;
    flex-shrink: 0;
    background-image: var(--icon);
    background-size: contain;
    background-position: center center;
    position: relative;
    top: var(--primary-cta__offset, 1px);

    @include transition(transform);
  }

  &:hover {
    &::after {
      transform: translateX(5px);
    }
  }

  :global(.dark) & {
    --colors__component: var(--colors__white);
    --colors__background: var(--colors__white);
    --icon: url('/icons/arrow-right-white.svg');
  }

  &:global(.no-icon) {
    --icon: none;
  }

  &:global(.external),
  &[target='_blank'] {
    --primary-cta__offset: 0px;
    --icon: url('/icons/arrow-diagonal-right-navy.svg');

    :global(.dark) & {
      --icon: url('/icons/arrow-diagonal-right-white.svg');
    }
  }
}
