@import './../../../styles/sass-resources/sass-resources';

$border-style: 1px solid var(--colors__navy);

.main {
  border: $border-style;
  border-bottom: var(--CoreMaterialCard__border-bottom, #{$border-style});
  background-color: var(--colors__white);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing);
  position: relative;
  padding: var(--spacing);

  @include background-animation;
  @include focus-inset;

  --colors__background: var(--colors__light-silver);
}

.track {
  @include fonts__track-tag;
}

.name {
  @include fonts__h4;

  text-align: left;
}

.session-tags {
  @include fonts__eyebrow;

  margin-top: var(--spacing-compact);
  display: flex;
  flex-flow: row wrap;
  gap: var(--spacing);
}
