@import './../../../styles/sass-resources/sass-resources';

.main {
  display: flex;
  flex-direction: column;
  gap: var(--spacing);
}

.filter-label {
  @include fonts__h6;
}

.container {
  padding: var(--spacing-compact);
  border: 1px solid currentcolor;
  position: relative;
  display: flex;
  gap: var(--spacing-compact);
  justify-content: space-between;
  align-items: center;

  @include focus-within;
}

.select {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;

  // To address issue in Safari where the height could not be changed unless this
  // property is used. https://stackoverflow.com/questions/13252835/safari-change-select-height
  appearance: none;
}

.icon {
  width: 1rem;
  height: 1rem;
}
