@import './../../../styles/sass-resources/sass-resources';

.main {
  @include primary-cta;
}

.icon {
  width: 12px;
  height: 12px;
  flex-shrink: 0;
}
