@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: var(--fade-opacity, 1);
  }
}

@keyframes fade-out {
  0% {
    opacity: var(--fade-opacity, 1);
  }

  100% {
    opacity: 0;
  }
}

@keyframes slide-in {
  0% {
    transform: translateY(var(--slide-length, 100%));
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes slide-out {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(var(--slide-length, 100%));
  }
}

@keyframes slide-right-in {
  0% {
    transform: translateX(calc(-1 * var(--slide-length, 100%)));
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes slide-right-out {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(calc(-1 * var(--slide-length, 100%)));
  }
}

@keyframes slide-in-down {
  0% {
    transform: translateY(calc(-1 * var(--slide-length, 100%)));
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes slide-out-down {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(calc(-1 * var(--slide-length, 100%)));
  }
}

// ark ui looks for animations to delay adding it's hidden css class so we add a css animation that does nothing because we want the real css animation on a child component
@keyframes do-nothing {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}
