@import './../../../styles/sass-resources/sass-resources';

$border-style: 1px solid var(--colors__navy);

.main {
  border: $border-style;
  border-bottom: var(--CoreVideoCard__border-bottom, #{$border-style});
  background-color: var(--colors__white);
  width: 100%;

  &:hover {
    --scale-button: 1.2;
    --background-size: 100% 100%;
    --background-position: left 0px;
  }

  &.list {
    @include background-animation;

    --colors__background: var(--colors__light-silver);
  }

  @include media('>=tablet-portrait') {
    &.list {
      display: flex;
      flex-direction: row;
      padding: var(--spacing);
      gap: var(--spacing);
    }
  }
}

.image {
  position: relative;
  flex-shrink: 0;
  background: var(--colors__navy);
  aspect-ratio: 16 / 9;

  .list & {
    @include media('>=tablet-portrait') {
      width: 30%;
    }
  }
}

.track {
  @include fonts__track-tag;
}

.name {
  @include fonts__h4;

  text-align: left;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing);
  position: relative;
}

.session-tags {
  @include fonts__eyebrow;

  display: flex;
  flex-flow: row wrap;
  gap: var(--spacing);
  margin-top: var(--spacing-compact);
}

.play-button {
  position: absolute;
  left: var(--spacing);
  bottom: var(--spacing);
  display: flex;
  justify-content: center;
  z-index: 1;
  padding: 12px var(--spacing-compact);
  gap: var(--spacing-compact);

  @include fonts__primary-cta;

  align-items: center;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--colors__yellow);
    border-radius: 22px;
    border: 1px solid var(--colors__navy);
    z-index: -1;

    @include transition(transform);

    transform: scale(var(--scale-button, 1));
  }
}

.play-icon {
  flex-shrink: 0;
  width: 10px;
  height: 12px;
}

.card-content {
  padding: var(--spacing);

  .grid & {
    @include background-animation;

    --colors__background: var(--colors__light-silver);
  }

  @include media('>=tablet-portrait') {
    .list & {
      padding: 0;
    }
  }
}
