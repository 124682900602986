@import './../../../styles/sass-resources/sass-resources';

.main {
  background: var(--colors__navy);
  color: var(--colors__white);
  display: flex;
  flex-direction: column;
  gap: var(--responsive-spacing);

  &:global(.light) {
    color: var(--colors__navy);
    background: var(--colors__light-silver);
  }

  &.no_image {
    text-align: center;
  }

  &.image_on_left {
    flex-direction: column-reverse;
  }

  @include media('>=tablet-landscape') {
    &.image_on_right {
      flex-direction: row;
    }

    &.image_on_left {
      flex-direction: row-reverse;
    }
  }
}

.content {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-spacious);
  max-width: 880px;
  margin-inline: auto;
}

.headline {
  @include fonts__h3;
}

.ctas {
  display: flex;
  flex-direction: row;
  gap: var(--spacing);

  .no_image & {
    justify-content: center;
  }
}

.image {
  position: relative;
  flex-shrink: 0;

  @include media('<tablet-landscape') {
    min-height: 300px;
  }

  @include media('>=tablet-landscape') {
    width: 50%;
  }
}
