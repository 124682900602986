:root {
  --spacing-tight: 0.5em;
  --spacing-compact: 1rem;
  --spacing: 2rem;
  --spacing-spacious: 4rem;
  --spacing-cavernous: 6rem;
  --responsive-spacing: var(--spacing);

  @include media('>=tablet-landscape') {
    --responsive-spacing: var(--spacing-spacious);
  }

  @include media('>=desktop') {
    --responsive-spacing: var(--spacing-cavernous);
  }
}
