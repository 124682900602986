@import './../../../../../styles/sass-resources/sass-resources';

.main {
  display: flex;
  flex-direction: column;
  gap: var(--spacing);
}

.filter-label {
  @include fonts__h6;
}

.wrapper {
  border: 1px solid var(--colors__light-silver);
  padding: var(--spacing-compact);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: var(--spacing-compact);

  @include focus-within;
}

.field {
  display: contents;
}

.label-text {
  width: 100%;

  @include fonts__h6;
}

.input {
  flex-grow: 1;
  background-color: transparent;
  color: var(--colors__light-silver);

  &::placeholder {
    color: var(--colors__white);
    opacity: 0.7;
  }

  @include fonts__paragraph;

  // font size needs to be 16px in mobile in order to prevent zoom on iOS
  font-size: 16px;
}

.search-icon {
  height: 1rem;
  width: 1rem;
}

.close-button {
  display: none;

  &--visible {
    display: inline;
  }
}

.close-icon {
  height: 1rem;
  width: 1rem;
}
