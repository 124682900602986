@keyframes slide-down {
  from {
    height: 0;
    opacity: 0;
  }

  to {
    height: var(--height);
    opacity: 1;
  }
}

@keyframes slide-up {
  from {
    height: var(--height);
    opacity: 1;
  }

  to {
    height: 0;
    opacity: 0;
  }
}

@mixin accordion-content {
  overflow: hidden;

  &[data-scope='accordion'][data-part='item-content'][data-state='open'],
  &[data-scope='collapsible'][data-part='content'][data-state='open'] {
    animation: slide-down var(--transition-duration) var(--transition-easing);
  }

  &[data-scope='accordion'][data-part='item-content'][data-state='closed'],
  &[data-scope='collapsible'][data-part='content'][data-state='closed'] {
    animation: slide-up var(--transition-duration) var(--transition-easing);
  }
}
