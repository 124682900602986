:root {
  --spacer__default-spacing: var(--responsive-spacing);
}

.spacer {
  padding-top: var(--spacer__padding-top, var(--spacer__padding-y, var(--spacer__default-spacing)));
  padding-bottom: var(
    --spacer__padding-bottom,
    var(--spacer__padding-y, var(--spacer__default-spacing))
  );
}

.borders {
  position: relative;

  &::before,
  &::after {
    content: ' ';
    position: absolute;
    width: 100%;
    height: 1px;
    transform: translateX(-50%);
    left: 50%;
    right: 50%;
    background: var(--colors__navy);
  }

  &::before {
    top: 0;
  }

  &::after {
    top: 100%;
  }
}
