.modal {
  --contentGap: 16px;

  &__backdrop {
    position: fixed;
    width: 100vw;
    height: 100dvh;
    left: 0;
    top: 0;
    z-index: 5;
    background: rgb(0 0 0 / 80%);
    backdrop-filter: blur(4px);
  }

  &__backdrop:is([open], [data-open], [data-state='open']) {
    animation: fade-in 0.5s cubic-bezier(0.05, 0.7, 0.1, 1);
  }

  &__backdrop:is([closed], [data-closed], [data-state='closed']) {
    animation: fade-out 0.5s cubic-bezier(0.3, 0, 0.8, 0.15);
  }

  &__positioner {
    position: fixed;
    width: 100vw;
    height: 100dvh;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0;
    top: 0;
    z-index: 6;
  }

  &__content {
    width: 100%;
    max-height: calc(100vh - (4 * var(--contentGap)));
    max-width: var(--modalMaxWidth, calc(100vw - (4 * var(--contentGap))));
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: var(--contentGap);
    align-items: center;
  }

  &__content:is([open], [data-open], [data-state='open']) {
    animation: fade-in 0.5s cubic-bezier(0.05, 0.7, 0.1, 1);
  }

  &__content:is([closed], [data-closed], [data-state='closed']) {
    animation: fade-out 0.15s cubic-bezier(0.3, 0, 0.8, 0.15);
  }
}
