@import './../../../styles/sass-resources/sass-resources';

.main {
  display: flex;
  position: relative;
  flex-direction: column;
  background: var(--colors__navy);
  color: var(--colors__white);
  gap: var(--responsive-spacing);

  &:global(.light) {
    background: var(--colors__light-silver);
    color: var(--colors__navy);
  }

  &.image_on_left {
    --spacer__padding-top: 0px;

    flex-direction: column-reverse;
  }

  @include media('>=tablet-landscape') {
    flex-direction: row;

    &.image_on_right {
      flex-direction: row;
    }

    &.image_on_left {
      flex-direction: row-reverse;
    }
  }
}

.eyebrow,
.sub-headline {
  @include fonts__eyebrow;
}

.headline {
  @include fonts__h1;
}

.content {
  display: flex;
  gap: var(--spacing);
  flex-direction: column;
  align-items: flex-start;
  padding: var(--responsive-spacing);

  @include media('>=tablet-landscape') {
    width: 50%;

    .no_image & {
      width: 60%;
    }
  }
}

.description {
  margin-top: var(--spacing);
}

.image {
  @include media('<tablet-landscape') {
    min-height: 300px;
  }

  position: relative;
  flex-shrink: 0;
  width: 100%;

  @include media('>=tablet-landscape') {
    position: absolute;
    height: 100%;
    width: calc(50% - var(--responsive-spacing));
    top: 0;

    .image_on_left & {
      left: 0;
    }

    .image_on_right & {
      right: 0;
    }
  }
}

.cta {
  margin-top: var(--spacing);
}
