@import './../../../../../styles/sass-resources/sass-resources';

.main {
  display: none;

  @include media('>=desktop') {
    display: flex;
  }
}

.toggle {
  border-radius: 9999px;
  background: var(--colors__navy);
  padding: 14px 16px;
  color: var(--colors__white);
  border: 1px solid var(--colors__navy);

  @include transition(background-color, color);

  svg {
    width: 16px;
    height: 16px;
  }

  &[data-state='open'] {
    background: var(--colors__yellow);
    color: var(--colors__navy);
  }
}

.account {
  background: var(--colors__yellow);
  border: 1px solid var(--colors__navy);
  padding: var(--spacing) 0;
  display: flex;
  flex-direction: column;
  border-top: none;
}

.name {
  @include fonts__h5;

  padding: var(--spacing) var(--responsive-spacing);
}

.button {
  @include fonts__h6;
  @include background-animation;

  padding: var(--spacing-compact) var(--responsive-spacing);
  display: flex;
  justify-content: space-between;

  &:hover {
    color: var(--colors__white);
  }
}

.account-profile {
  @include media('<=desktop') {
    display: none;
  }

  @include media('>=desktop') {
    position: fixed;
    top: var(--NavigationHeader__height);
    right: 0;
    min-width: 448px;
    z-index: 1;

    &[data-state='open'] {
      @include slideInDown;
    }

    &[data-state='closed'] {
      @include slideOutDown;
    }
  }
}

.sign-out {
  width: 16px;
}
