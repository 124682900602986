@import './../../../../../styles/sass-resources/sass-resources';

.main {
  width: 100%;

  :global(.list) & {
    @include focus-inset;
  }
}

.modal {
  padding: var(--spacing);
  background: var(--colors__white);
  display: flex;
  flex-direction: column;
  gap: var(--spacing);

  @include media('>=tablet-landscape') {
    padding: var(--spacing-spacious);
  }
}

.content {
  --transition-duration: 600ms;
  --slide-length: 100vh;

  max-width: 750px;
  border: 1px solid var(--colors__navy);
  position: relative;
  max-height: 100vh;
  overflow-y: auto;
  width: calc(100vw - var(--responsive-spacing));

  &[data-state='open'] {
    @include slideIn;
  }

  &[data-state='closed'] {
    @include slideOut;
  }

  iframe {
    width: 100%;
    position: relative;
    aspect-ratio: 1.7778 / 1;
    height: 100%;
  }
}

.backdrop {
  @include modal-backdrop;
}

.positioner {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: var(--z-index__modal);
}

.close {
  position: absolute;
  top: var(--spacing-tight);
  right: var(--spacing-tight);
  width: 16px;
  height: 16px;

  @include media('>=tablet-landscape') {
    top: var(--spacing);
    right: var(--spacing);
  }
}
