@import './../../../styles/sass-resources/sass-resources';

.main {
  display: flex;
  flex-direction: column;
  gap: var(--responsive-spacing);
}

.lead {
  display: flex;
  flex-direction: column;
  gap: var(--spacing);

  @include media('>=tablet-landscape') {
    gap: var(--spacing-spacious);
  }
}

.items {
  display: grid;
  gap: var(--spacing);
  grid-template-columns: 1fr;

  @include media('>=tablet-landscape') {
    grid-template-columns: 1fr 1fr;
  }

  @include media('>=desktop') {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.item {
  border-top: 1px solid var(--colors__navy);
  padding-top: var(--spacing);
}
