@mixin modal-backdrop {
  background: var(--colors__navy);
  opacity: 0.5;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: var(--z-index__search-filters-modal);
  pointer-events: auto;

  --fade-opacity: 0.5;

  &[data-state='open'] {
    @include fadeIn;
  }

  &[data-state='closed'] {
    @include fadeOut;
  }
}
