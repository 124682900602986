@import './../../../../../styles/sass-resources/sass-resources';

.main {
  display: flex;
  flex-direction: column;
  gap: var(--spacing);
}

.filter-label {
  @include fonts__h6;
}

.group {
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-compact);
}

.checkbox {
  padding: var(--spacing-tight) var(--spacing-compact);

  @include fonts__eyebrow;

  border: 1px solid currentcolor;
  border-radius: 1.25rem;
  cursor: pointer;

  @include transition(background-color, color, border-color);

  &:hover,
  &[data-state='checked'] {
    background-color: var(--colors__yellow);
    color: var(--colors__navy);
  }

  @include focus-within;
}

.label {
  position: relative;
  top: 2px;
}
