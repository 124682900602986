@import './../../../styles/sass-resources/sass-resources';

.people-grid-item {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.wrapper {
  position: relative;
  height: 100%;
  width: 100%;
}

.image-container {
  position: absolute;
  background-color: var(--colors__navy);
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(180deg, rgb(0 0 0 / 0%) 27.52%, rgb(0 0 0 / 75%) 100%);
  }
}

.image {
  position: absolute;
  top: 0;
  left: 0;
}

.content-container {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: var(--spacing);
  color: var(--colors__white);

  .faculty & {
    padding: var(--spacing-compact);
  }
}

.name {
  @include fonts__h5;

  display: flex;
  flex-direction: column;

  .faculty & {
    @include fonts__h4;

    margin-bottom: var(--spacing);
  }
}

.title {
  margin-bottom: var(--spacing-compact);
}

.title,
.office {
  @include fonts__eyebrow;
}

.button-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.link {
  @include fonts__primary-cta;

  flex-grow: 1;
  width: 100%;
  display: flex;
  justify-content: center;

  &:first-child:not(:only-child) {
    --BoxCta__border-right: none;
  }
}
