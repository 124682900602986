@import './../../../styles/sass-resources/sass-resources';

.main {
  --border-radius: 9999px;

  display: flex;
  gap: var(--responsive-spacing);
  flex-direction: column;
}

.top {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: var(--spacing-compact);

  @include media('>=tablet-landscape') {
    flex-direction: row;
  }
}

.switch {
  @include media('>=tablet-landscape') {
    margin-left: auto;
  }
}

.header {
  @include fonts__h3;
}

.accordion {
  border-top: 1px solid var(--colors__navy);
}

.caret {
  @include transition(transform);

  margin-right: var(--spacing-compact);
  width: 16px;
}

.trigger {
  width: 100%;
  padding: var(--spacing) 0;
  text-align: left;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  &[data-state='open'] {
    .caret {
      transform: rotateX(180deg);
    }
  }
}

.trigger-label {
  font-weight: $fonts__font-weight--medium;
}

.trigger-label,
.content {
  width: 80%;
}

.text {
  padding-bottom: var(--spacing);
}

.content {
  @include accordion-content;
}
