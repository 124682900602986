@import './../../../../../styles/sass-resources/sass-resources';

.main {
  position: fixed;
  left: 0;
  background-color: var(--colors__royal);
  top: calc(var(--NavigationHeader__height) - 1px);
  width: 100vw;
  z-index: var(--z-index__global-menu);

  &[data-state='open'] {
    @include slideInDown;
  }

  &[data-state='closed'] {
    @include slideOutDown;
  }
}

.columns {
  display: flex;
  flex-direction: row;
  max-height: calc(100vh - var(--NavigationHeader__height));
  overflow-y: auto;
}

.column {
  display: flex;
  flex-direction: column;
  padding: var(--spacing) 0;
  height: 100%;

  .sessions &,
  .internal_links & {
    width: 50%;
  }

  &--0 {
    border-right: 1px solid var(--colors__navy);

    .internal_external_links & {
      width: 60%;
    }
  }

  &--1 {
    .internal_external_links & {
      width: 40%;
    }
  }
}

.links-label {
  @include fonts__h4;

  color: var(--colors__white);
  padding: var(--spacing);

  @include media('>=desktop') {
    padding: var(--spacing) var(--responsive-spacing);
  }
}
