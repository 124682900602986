@import './../../../../../styles/sass-resources/sass-resources';

.main {
  @include fonts__h5;
  @include background-animation;
  @include focus-inset;

  display: flex;
  gap: var(--spacing-tight);
  justify-content: space-between;
  align-items: flex-end;
  padding: var(--spacing-compact) var(--responsive-spacing);
  color: var(--colors__white);
  text-align: left;

  &::after {
    flex-shrink: 0;
    width: 16px;
    content: url('/icons/arrow-right-white.svg');

    @include transition(transform);
  }

  &[target='_blank'] {
    &::after {
      width: 16px;
      content: url('/icons/arrow-diagonal-right-white.svg');
    }
  }

  &.secondary {
    @include fonts__h6;
  }

  @include media('>=desktop') {
    &:hover {
      &::after,
      .text {
        transform: translateX(1rem);
      }
    }
  }

  @include media('>=desktop') {
    padding: var(--spacing) var(--responsive-spacing);

    &.secondary {
      padding: var(--spacing-compact) var(--responsive-spacing);
    }
  }
}

.labels {
  display: flex;
  flex-direction: row;
  gap: var(--spacing-compact);
  position: relative;

  @include media('>=desktop') {
    gap: var(--spacing-spacious);
  }
}

.number,
.subheadline {
  @include fonts__eyebrow;
}

.number {
  align-self: flex-start;
  padding-top: var(--spacing-compact);

  @include media('>=desktop') {
    position: absolute;
    left: calc(-1 * var(--spacing-spacious));
    top: 0;
  }
}

.text {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-compact);

  @include transition(transform);
}
