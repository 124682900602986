html {
  font-size: 100%;
  box-sizing: border-box;
  line-height: 1.375;
  scroll-behavior: smooth;
}

*,
*::before,
*::after {
  box-sizing: inherit;
  outline: none;
  line-height: inherit;
}

body {
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-underline-position: under;
  color: var(--color__body-copy-text);
  background: var(--colors__light-silver);

  @include fonts__sans-serif-regular;
}

main {
  display: block;
}

a {
  color: inherit;
  text-decoration: none;
}

button {
  color: inherit;
  padding: 0;
  margin: 0;
  border: none;
  background: none;
}

a,
button,
[type='button'],
input[type='checkbox'] {
  cursor: pointer;

  @include focus;
}

ul {
  margin: 0;
  padding: 0;
}

li {
  margin-bottom: 0;
  list-style-type: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

fieldset {
  border: 0 none;
  padding: 0;
  margin: 0;
}

figure {
  margin: 0;
}

address {
  font-style: normal;
}

img {
  max-width: 100%;
}

input {
  border: none;

  &:invalid {
    box-shadow: none;
  }
}

b,
strong {
  font-weight: $fonts__font-weight--bold;
}

header {
  display: contents;
}

blockquote {
  margin: 0;
}
