@import './../../../styles/sass-resources/sass-resources';

.columns {
  background-color: var(--colors__white);
  position: relative;
  z-index: 1;

  --spacer__padding-y: 0px;

  display: flex;
  flex-direction: column;

  @include media('>=tablet-landscape') {
    flex-direction: row;
  }
}

.column {
  flex: 1 1 0;
  padding: var(--responsive-spacing);

  @include media('<tablet-landscape') {
    + .column {
      padding-top: 0;
    }
  }

  @include media('>=tablet-landscape') {
    &:nth-child(2) {
      border-left: 1px dashed var(--colors__navy);
    }

    &:nth-child(2):nth-last-child(2) {
      border-right: 1px dashed var(--colors__navy);
    }
  }
}

.links {
  display: flex;
  flex-direction: column;
  gap: var(--spacing);
}

.footer {
  @media (min-height: $breakpoints__vertical-small) {
    position: sticky;
  }

  bottom: 0;
  background: var(--colors__yellow);
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  z-index: 0;
  gap: var(--spacing);

  @include media('>=tablet-landscape') {
    flex-direction: row;
  }
}

.logo {
  width: 132px;
  height: 46px;
  flex-shrink: 0;

  @include media('>=tablet-landscape') {
    position: relative;
    top: 8px;
  }
}

.text {
  display: flex;
  gap: var(--spacing);
  flex-direction: column;

  @include media('>=tablet-landscape') {
    width: 50%;
  }
}

.icon {
  display: flex;
  align-items: flex-end;
}
