:root {
  --container__padding-x: var(--responsive-spacing);
}

.container-6 {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: var(--container__max-width, calc(rem(1100px) + var(--container__padding-x) * 2));
  padding-left: var(--container__padding-x);
  padding-right: var(--container__padding-x);
}

.container-8 {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: var(--container__max-width, calc(rem(1408px) + var(--container__padding-x) * 2));
  padding-left: var(--container__padding-x);
  padding-right: var(--container__padding-x);
}
