@import './../../../../../styles/sass-resources/sass-resources';

$border-style: 1px solid var(--colors__navy);

.main {
  padding-top: var(--responsive-spacing);
}

.top {
  display: flex;
  justify-content: space-between;
  border-bottom: $border-style;
}

.triggers {
  display: flex;
  align-items: center;
}

.trigger {
  padding: var(--spacing-tight);
  position: relative;
  height: 100%;

  @include background-animation--small(false);

  &[aria-selected='true'] {
    pointer-events: none;
  }
}

.trigger-label {
  @include fonts__primary-cta;
}

.buttons {
  @include media('<tablet-portrait') {
    display: none;
  }
}

.button {
  padding: var(--spacing-compact);
  border-top: $border-style;
  border-left: $border-style;

  &:last-child {
    border-right: $border-style;
  }

  @include transition(color, background-color);

  &--grid {
    &.button--active,
    &:hover {
      background-color: var(--colors__yellow);
    }
  }

  &.button--active,
  &:hover {
    &.button--grid {
      background-color: var(--colors__yellow);
    }

    &.button--list {
      background-color: var(--colors__royal);
      color: var(--colors__light-silver);
      outline-color: var(--colors__royal);
    }
  }
}

.icon {
  width: 1rem;
  height: 1rem;
}
