@import './../../../../../styles/sass-resources/sass-resources';

.main {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-spacious);
  align-items: center;
  height: 100%;
  justify-content: center;
}

.label {
  @include fonts__h5;

  text-align: center;
}

.message {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--spacing);
}

.icon {
  width: 32px;
  height: 32px;

  svg {
    width: 100%;
    height: 100%;
  }
}
